import { FC, useEffect, useState } from 'react'
import './ProjectsAction.scss'
import { axiosInstance } from 'shared/api'
import fileDownload from 'js-file-download'
import {
	ChangeProjectPopup,
	ChoosePartnerPopup
} from '../../../profile/ui/popup-profile'
import { useAlertMessage, useOutsideClick } from 'shared/hooks'
import { useProjectByIdActions } from 'features/projects/api/useProjectByIdActions'
import { IUserUpdate } from 'shared/services/types/user.types'
import { useGetTariff } from 'features/tariff/api/useGetTariff'
import { Link } from 'react-router-dom'
import { useGetProjectInfo } from 'features/projects/api/useGetProjects'

interface IProps {
	project_data: any
	companyUsersData?: IUserUpdate[]
}

const ProjectsAction: FC<IProps> = ({ project_data, companyUsersData }) => {
	const { addAlertMessage } = useAlertMessage()
	const { deleteProject, changeProjectOwner } = useProjectByIdActions(
		project_data.id
	)
	const [showActionsMenu, setShowActionsMenu] = useState(false)
	const [openProjectChange, setOpenProjectChange] = useState(false)
	const [showEmployeeMenu, setShowEmployeeMenu] = useState(false)
	const [openChoosePartner, setOpenChoosePartner] = useState(false)
	const [projectsProductsList, setProjectsProductsList] = useState<any[]>()
	const [id, setId] = useState<number>()

	const { data: dataProj } = useGetProjectInfo(id)

	const { data } = useGetTariff()
	const tarrifId = data?.tariff?.id

	const { ref } = useOutsideClick(() => {
		setShowActionsMenu(false)
		setShowEmployeeMenu(false)
	})

	const handleProjectChange = () => {
		setOpenProjectChange(true)
	}

	const toggleActionsMenu = () => {
		setShowActionsMenu(!showActionsMenu)
	}

	const toggleEmployeesMenu = () => {
		setShowEmployeeMenu(!showEmployeeMenu)
	}

	const closeActionsMenu = () => {
		setShowActionsMenu(false)
	}

	const pickPartnersMenu = () => {
		setId(project_data.id)
		setOpenChoosePartner(true)
	}

	const closeEmployeesMenu = () => {
		setTimeout(() => {
			setShowEmployeeMenu(false)
		}, 0)
	}

	const onDeleteProject = async () => {
		try {
			await deleteProject.mutateAsync()
		} catch (error) {
			console.log(error)
		}
	}

	const passProject = async (id: number) => {
		try {
			await changeProjectOwner.mutateAsync(id)
			addAlertMessage('Папка успешно передана', 'success')
		} catch (error: any) {
			addAlertMessage(error, 'error')
		}
	}

	const getExcelFile = async () => {
		try {
			const data = await axiosInstance.get(`/project/${project_data.id}/`)
			await axiosInstance
				.post(
					'/project/generate/excel/',
					{
						project_name: data.data.name,
						products: [
							...data.data.products.map((i: any) => {
								return {
									count: i.count,
									product_id: i.id
								}
							})
						]
					},
					{ responseType: 'blob' }
				)
				.then(response => {
					fileDownload(response.data, `${project_data.name}.xlsx`)
				})
		} catch (error) {
			console.log(error)
		}
	}

	const getProjectsProducts = async () => {
		if (!dataProj) return

		try {
			//@ts-ignore
			const childrenProducts = dataProj?.child_projects?.reduce((acc, item) => {
				return [...acc, ...item.products]
			}, [])

			//@ts-ignore
			setProjectsProductsList([...dataProj.products, ...childrenProducts])
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		getProjectsProducts()
	}, [dataProj])

	return (
		<>
			<div className='product-row' ref={ref}>
				<button className='action-button' onClick={toggleActionsMenu}>
					Действие
				</button>
				{showActionsMenu && (
					<div className='action-menu'>
						<button onClick={closeActionsMenu}>&#10006;</button>
						<button onClick={onDeleteProject}>Удалить</button>
						<button onClick={handleProjectChange}>Редактировать</button>
						<button onClick={toggleEmployeesMenu}>Передать сотруднику</button>
						<button onClick={pickPartnersMenu}>Подобрать партнера</button>
						<button onClick={getExcelFile}>Скачать в EXL</button>

						{showEmployeeMenu && (
							<div className='employee-menu'>
								<button onClick={closeEmployeesMenu}>&#10006;</button>
								{![2, 4].includes(tarrifId!) ? (
									<div className='employee-empty'>
										Необходимо приобрести
										<br />
										<Link to={'/account/tariffs'}> подходящий тариф</Link>
									</div>
								) : companyUsersData?.length ? (
									companyUsersData.map(i => {
										return (
											<button key={i.id} onClick={() => passProject(i.id)}>
												{i.last_name + ' ' + i.first_name + ' '}
											</button>
										)
									})
								) : (
									<div className='employee-empty'>
										<p>У вас нет сотрудников.</p>
										<Link to={'/account/employees'}>Добавить сотрудников</Link>
									</div>
								)}
							</div>
						)}
					</div>
				)}
			</div>
			<ChangeProjectPopup
				isOpen={openProjectChange}
				setIsOpen={setOpenProjectChange}
				data={project_data}
			/>

			<ChoosePartnerPopup
				isOpen={openChoosePartner}
				setIsOpen={setOpenChoosePartner}
				data={dataProj}
				dataProject={projectsProductsList}
			/>
		</>
	)
}

export default ProjectsAction

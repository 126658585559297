import { FC } from 'react'
import { Controller } from 'react-hook-form'
import { IPropsSelect } from '../types'
import Select from 'react-select'
import clsx from 'clsx'

export const ReactSelect: FC<IPropsSelect> = ({
	control,
	name,
	rules,
	isSearch,
	defaultValue,
	error,
	label,
	required,
	options,
	placeholder,
	className,
	classNamePrefix,
	disabled,
	info
}) => {
	const errorMessage =
		typeof error === 'string' ? error : error?.[name]?.message

	return (
		<Controller
			name={name}
			control={control}
			rules={rules}
			render={({ field: { value, onChange } }) => {
				const isActive = value

				return (
					<div
						className={clsx(
							'select-block',
							isActive && 'active',
							errorMessage && 'error',
							className
						)}
					>
						<div className='input-placeholder'>
							{label}
							{required && <span>*</span>}
						</div>
						<Select
							options={options}
							isSearchable={isSearch}
							classNamePrefix={clsx(
								'multi-select-stroke',
								'language-select',
								classNamePrefix
							)}
							isDisabled={disabled}
							value={value}
							autoFocus={false}
							components={{
								SingleValue: SingleAllValue,
								//@ts-ignore
								Option: AllOption
							}}
							placeholder={placeholder}
							onChange={onChange}
							defaultValue={defaultValue}
						/>

						{info && (
							<>
								<div className='input-note'>
									<svg
										width='18'
										height='18'
										viewBox='0 0 18 18'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M7.59375 6.65625C7.59375 5.8796 8.22335 5.25 9 5.25C9.77665 5.25 10.4062 5.8796 10.4062 6.65625C10.4062 7.17183 10.1288 7.62261 9.71502 7.86741C9.35853 8.07832 9 8.39829 9 8.8125V9.75'
											stroke='#777777'
											strokeLinecap='round'
										></path>
										<circle cx='9' cy='12' r='0.75' fill='#777777'></circle>
										<path
											d='M5.25 2.50337C6.35315 1.86523 7.63392 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 7.63392 1.86523 6.35315 2.50337 5.25'
											stroke='#777777'
											strokeLinecap='round'
										></path>
									</svg>
									<div className='input-note-desc'>{info}</div>
								</div>
							</>
						)}

						<div className='input-error'>
							{/* @ts-ignore */}
							{errorMessage && <span>{errorMessage}</span>}
						</div>
					</div>
				)
			}}
		/>
	)
}

const SingleAllValue: FC<any> = ({ data }) => (
	<div
		style={{
			display: 'flex',
			alignItems: 'center',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			gap: '10px'
		}}
	>
		{data.flag && (
			<img src={data.flag} alt={data.label} width='24' height='16' />
		)}

		<span>{data.label}</span>
	</div>
)

const AllOption: FC<any> = ({ innerProps, label, data }) => {
	return (
		<div
			className='custom-option'
			style={{
				display: 'flex',
				alignItems: 'center',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				gap: '10px'
			}}
			{...innerProps}
		>
			{data?.flag && <img src={data.flag} alt={label} width='24' height='16' />}

			<span>{label}</span>
		</div>
	)
}

import { Layout } from 'shared/ui/layout'

const PrivacyPolicy = () => {
	const webSite = process.env.REACT_APP_WEB_SITE || 'https://kira.pw/docs'

	return (
		<Layout>
			<div className='container'>
				<h2
					className={
						'common-page__subheader content__title-main common-page__title '
					}
				>
					Политика конфиденциальности
				</h2>

				<div className='static-info'>
					<h5 className='common-page__subheader common-page__title'>
						1. ОБЩИЕ ПОЛОЖЕНИЯ
					</h5>
					<p>
						1.1. Политика утверждена в соответствии с п. 2 ст. 18.1 Федерального
						закона «О персональных данных» и разработана с учетом требований,
						законодательных и иных нормативных правовых актов Российской
						Федерации в области персональных данных.
					</p>
					<p>
						1.2. Действие Политики распространяется на Персональную информацию,
						полученную как до, так и после утверждения настоящей Политики.{' '}
					</p>
					<p>
						1.3. Настоящая Политика конфиденциальности применяется только к
						Сайту {webSite}. Сайт
						{webSite} не контролирует и не несет
						ответственности за сайты третьих лиц, на которые Пользователь может
						перейти по ссылкам, доступным на Сайте {webSite}.
					</p>
					<h5 className='common-page__subheader common-page__title'>
						2. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ
					</h5>
					<p>
						Персональные данные (Персональная информация) — любая информация,
						относящаяся к прямо или косвенно определенному, или определяемому
						физическому лицу (субъекту Персональных данных), в том числе
						Пользователю Сайтов Оператора.
					</p>
					<p>
						Информация — сведения (сообщения, данные) независимо от формы их
						представления.
					</p>
					<p>
						Оператор — государственный орган, муниципальный орган, юридическое
						или физическое лицо, самостоятельно или совместно с другими лицами
						организующие и (или) осуществляющие Обработку персональной
						информации, а также определяющие цели обработки Персональной
						информации, состав Персональных данных, подлежащих обработке,
						действия (операции), совершаемые с персональной информацией, в
						частности ООО «_________».
					</p>
					<p>Пользователь – любой посетитель Сайта Оператора.</p>
					<p>
						Сайт – веб-сайт в сети «Интернет», расположенный по ссылке {webSite} и
						принадлежащий ООО «______».
					</p>
					<p>
						Обработка персональных данных — любое действие (операция) или
						совокупность действий (операций), совершаемые с использованием
						средств автоматизации или без использования таких средств с
						Персональными данными, включая сбор, запись, систематизацию,
						накопление, хранение, уточнение (обновление, изменение), извлечение,
						использование, передачу (распространение, предоставление, доступ),
						обезличивание, блокирование, удаление, уничтожение Персональных
						данных.
					</p>
					<p>
						Автоматизированная обработка персональных данных — Обработка
						персональных данных с помощью средств вычислительной техники.
					</p>
					<p>
						Предоставление персональных данных — действия, направленные на
						раскрытие Персональных данных определенному лицу или определенному
						кругу лиц.
					</p>
					<p>
						Распространение персональных данных — любые действия, направленные
						на раскрытие Персональной информации неопределенному кругу лиц
						(передача Персональной информации) или на ознакомление с
						Персональной информацией неограниченного круга лиц, в том числе
						обнародование Персональной информации в средствах массовой
						информации, размещение в информационно-телекоммуникационных сетях
						или предоставление доступа к Персональной информации каким-либо иным
						способом;
					</p>
					<p>
						Блокирование персональных данных — временное прекращение обработки
						Персональных данных (за исключением случаев, когда обработка
						необходима для уточнения Персональных данных).
					</p>
					<p>
						Уничтожение персональных данных — действия, в результате которых
						становится невозможным восстановить содержание Персональных данных в
						информационной системе Персональных данных и (или) в результате
						которых уничтожаются материальные носители Персональных данных.
					</p>
					<p>
						Обезличивание персональных данных — действия, в результате которых
						становится невозможным без использования дополнительной информации
						определить принадлежность Персональных данных конкретному
						Пользователю или иному субъекту Персональных данных.
					</p>
					<p>
						Информационная система персональных данных — совокупность
						содержащихся в базах данных Персональных данных и обеспечивающих их
						обработку информационных технологий и технических средств.
					</p>
					<p>
						Cookies - фрагменты данных, отправляемых веб-сервером браузеру при
						посещении Cайта Пользователем. Оператор автоматически получает
						некоторые виды информации, извлекаемой в процессе взаимодействия
						Пользователей с Cайтом. Речь идет о технологиях и сервисах, таких
						как веб-протоколы, куки, веб-отметки, а также приложения и
						инструменты. Куки – это часть данных, автоматически располагающаяся
						на жестком диске компьютера при каждом посещении веб-сайта. Таким
						образом, куки – это уникальный идентификатор браузера для веб-сайта.
						Куки дают возможность хранить информацию на сервере и помогают легче
						ориентироваться в веб-пространстве, а также позволяют осуществлять
						анализ сайта, оценку результатов и таргетирование поведенческой
						рекламы. Большинство веб-браузеров разрешают использование куки,
						однако можно изменить настройки для отказа от работы с куки или
						отслеживания пути их рассылки. При этом некоторые ресурсы могут
						работать некорректно, если работа куки в браузере будет запрещена.
					</p>

					<h5 className='common-page__subheader common-page__title'>
						3. ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЕЙ
					</h5>
					<p>
						3.1. Сайт собирает и хранит только ту персональную информацию,
						которая необходима для предоставления сервисов или исполнения
						соглашений и договоров с Пользователем, за исключением случаев,
						когда законодательством предусмотрено обязательное хранение
						персональной информации в течение определенного законом срока.
					</p>
					<p>
						3.2. Персональную информацию Пользователя Сайт обрабатывает в
						следующих целях:
					</p>
					<p>
						3.2.1. Идентификации Пользователя, зарегистрированного на Сайте, для
						подбора актуальной информации по поисковым запросам Пользователя.
					</p>
					<p>
						3.2.2. Предоставления Пользователю доступа к персонализированным
						ресурсам Сайта.
					</p>
					<p>
						3.2.3. Установления с Пользователем обратной связи, включая
						направление уведомлений, запросов, касающихся использования Сайта,
						оказания услуг, обработку запросов и заявок от Пользователя.
					</p>
					<p>
						3.2.4. Определения места нахождения Пользователя для обеспечения
						безопасности, предотвращения мошенничества.
					</p>
					<p>
						3.2.5. Подтверждения достоверности и полноты персональных данных,
						предоставленных Пользователем.
					</p>
					<p>
						3.2.6. Создания учетной записи для использования Сайта
						Пользователем, если Пользователь дал согласие на создание учетной
						записи.
					</p>
					<p>3.2.7. Использования любых иных имеющихся на Сайте сервисов.</p>
					<p>
						3.2.8. Предоставления Пользователю эффективной клиентской и
						технической поддержки при возникновении проблем, связанных с
						использованием Сайта.
					</p>
					<p>
						3.2.9. Осуществления рекламной деятельности с согласия Пользователя.
					</p>

					<h5 className='common-page__subheader common-page__title'>
						4. УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЕЙ И ЕЕ
						ПЕРЕДАЧИ ТРЕТЬИМ ЛИЦАМ
					</h5>
					<p>
						4.1. Сайт хранит персональную информацию Пользователей в
						соответствии с настоящей Политикой конфиденциальности.
					</p>
					<p>
						4.2. В отношении персональной информации Пользователя сохраняется ее
						конфиденциальность, кроме случаев добровольного предоставления
						Пользователем информации о себе для общего доступа неограниченному
						кругу лиц.{' '}
					</p>
					<p>
						4.3. Сайт вправе передать персональную информацию Пользователя
						третьим лицам в следующих случаях:
					</p>
					<p>4.3.1. Пользователь выразил согласие на такие действия.</p>
					<p>
						4.3.2. Передача необходима для использования Пользователем
						определенного сервиса либо для исполнения определенного соглашения
						или договора с Пользователем.
					</p>
					<p>
						4.3.3. Передача предусмотрена российским или иным применимым
						законодательством в рамках установленной законодательством
						процедуры.
					</p>
					<p>
						4.4. Обработка персональных данных Пользователя осуществляется без
						ограничения срока следующими способами: сбор, запись,
						систематизация, накопление, хранение, уточнение (обновление,
						изменение), извлечение, использование, передача (распространение,
						предоставление, доступ), обезличивание, блокирование, удаление,
						уничтожение персональных данных, в том числе в информационных
						системах персональных данных с использованием средств автоматизации
						или без использования таких средств. Обработка персональных данных
						Пользователей осуществляется в соответствии с Федеральным законом от
						27.07.2006 N 152-ФЗ "О персональных данных".
					</p>
					<p>
						4.5. Оператор принимает необходимые организационные и технические
						меры для защиты персональной информации Пользователя от
						неправомерного или случайного доступа, уничтожения, изменения,
						блокирования, копирования, распространения, а также от иных
						неправомерных действий третьих лиц.
					</p>
					<p>
						4.6. Оператор совместно с Пользователем принимает все необходимые
						меры по предотвращению убытков или иных отрицательных последствий,
						вызванных утратой или разглашением персональных данных Пользователя.
					</p>

					<h5 className='common-page__subheader common-page__title'>
						5. ОБЯЗАТЕЛЬСТВА СТОРОН
					</h5>
					<p>5.1. Пользователь обязан:</p>
					<p>
						5.1.1. Предоставлять информацию о Персональных данных, необходимую
						для пользования Сайтом, а также иными сервисами Оператора.
					</p>
					<p>
						5.1.2. Сообщать Оператору об уточнении (обновлении, изменении) своих
						Персональных данных.
					</p>
					<p>
						5.1.3. Не сообщать третьим лицам данные, используемые им для
						идентификации на Сайте, а также принимать необходимые и разумные
						меры, направленные на недопущение получения третьими лицами данных.
					</p>
					<p>5.2. Пользователь вправе:</p>
					<p>
						5.2.1. Принимать решение о предоставлении его Персональных данных
						Оператору.
					</p>
					<p>
						5.2.2. В любое время отозвать согласие на обработку своих
						Персональных данных.
					</p>
					<p>
						5.2.3. Вносить, дополнять или изменять обрабатываемую Персональную
						информацию.
					</p>
					<p>
						5.2.4. Требовать от Оператора уточнения его Персональных данных, их
						блокирования или уничтожения в случае, если Персональные данные
						являются неполными, устаревшими, неточными
					</p>
					<p>
						5.2.5. Требовать от Оператора получать информацию, касающуюся
						Обработки персональных данных, за исключением случаев,
						предусмотренных федеральными законами. Перечень информации и порядок
						ее получения установлен действующим законодательством в сфере
						обработки персональных данных.
					</p>
					<p>5.3. Оператор обязан:</p>
					<p>
						5.3.1. Использовать полученную информацию исключительно для целей,
						указанных в настоящей Политике.
					</p>
					<p>
						5.3.2. Обеспечить хранение Персональной информации в тайне, не
						разглашать без предварительного письменного разрешения Пользователя,
						а также не осуществлять продажу, обмен, опубликование либо
						разглашение иными возможными способами переданных Персональных
						данных Пользователя, за исключением случаев, предусмотренных
						пунктами 4.2 и 4.3 настоящей Политики.
					</p>
					<p>
						5.3.3. По требованию Пользователя уточнять обрабатываемую
						Персональную информацию, блокировать или удалять, если Персональная
						информация являются неполной, устаревшей, неточной, незаконно
						полученной или не является необходимой для заявленной цели
						обработки.
					</p>
					<p>
						5.3.4. Принимать меры предосторожности для защиты конфиденциальности
						Персональной информации Пользователя согласно порядку, обычно
						используемому для защиты такого рода информации в существующем
						деловом обороте.
					</p>
					<p>
						5.3.5. Осуществить блокирование Персональной информации, относящейся
						к соответствующему Пользователю, с момента обращения или запроса
						Пользователя или его законного представителя либо уполномоченного
						органа по защите прав субъектов Персональных данных на период
						проверки, в случае выявления недостоверных Персональных данных или
						неправомерных действий.
					</p>
					<p>
						5.3.6. В случае отзыва Пользователем согласия на обработку своих
						Персональных данных прекратить обработку и уничтожить Персональные
						данные в срок, не превышающий 30 (Тридцати) календарных дней с даты
						поступления указанного отзыва.
					</p>
					<p>
						В случае отзыва субъектом Персональных данных согласия на Обработку
						персональных данных Оператор вправе продолжить Обработку
						персональных данных без согласия субъекта Персональных данных при
						наличии оснований, установленных законом.
					</p>
					<p>
						5.3.7. Определить требования к своему персоналу, степень
						ответственности работников за обеспечение безопасности Персональных
						данных Пользователей Сайта. Проводить ознакомление работников,
						осуществляющих обработку Персональной информации, с положениями
						законодательства Российской Федерации по обеспечению безопасности
						Персональных данных и требованиями к защите Персональных данных,
						документами, определяющими политику Оператора в отношении обработки
						Персональных данных, локальными актами по вопросам обработки
						Персональных данных.
					</p>
					<p>5.4. Оператор вправе:</p>
					<p>
						5.4.1. Собирать Персональную информацию Пользователей для решения
						уставных и коммерческих задач при условии соблюдения требований
						действующего законодательства РФ.
					</p>
					<p>
						5.4.2. Самостоятельно подбирать комплекс организационных,
						технических и программных мер для защиты Персональной информации.
					</p>
					<p>
						5.4.3. Передавать Персональные данные Пользователей для обработки
						третьим лицам, при выполнении требований законодательства РФ по
						оформлению согласия.
					</p>
					<h5 className='common-page__subheader common-page__title'>
						6. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ
					</h5>
					<p>
						6.1. В настоящую Политику могут быть внесены изменения. Оператор
						вправе вносить изменения по своему усмотрению, в том числе, но не
						ограничиваясь, в случаях, когда соответствующие изменения связаны с
						изменениями в применимом законодательстве, а также когда
						соответствующие изменения связаны с изменениями в работе Сайта.
					</p>
					<p>
						Политика (а также ее последующие версии) вступает в силу с момента
						ее размещения на Сайте Оператора, если иное не предусмотрено новой
						редакцией Политики.
					</p>
					<p>
						Электронная версия действующей редакции Политики размещена на Сайте
						Оператора в сети «Интернет». Ссылки на данную Политику содержатся на
						всех страницах сайта, где осуществляется сбор Персональной
						информации.
					</p>
					<p>
						6.2. Пользователь может получить разъяснения по вопросам, касающимся
						обработки его Персональной информации, обратившись к Оператору с
						помощью электронной почты ________.
					</p>
					<p>
						6.3. К настоящей Политике и отношениям между Пользователем и
						Оператором применяются нормы действующего законодательства
						Российской Федерации.
					</p>
					<p>
						6.4. Ответственность за нарушение Оператором условий Политики
						определяется действующим законодательством Российской Федерации.
					</p>
					<p>6.5. Адрес размещения в сети Интернет: {webSite}</p>
					<p>6.6. Дата публикации и вступления в силу: __.__.2023</p>
				</div>
			</div>
		</Layout>
	)
}

export default PrivacyPolicy

import { useState, useEffect } from 'react'
import './ProfilePartners.scss'
import { FirstNumber, SecondNumber, ThirdNumber } from '../../../assets/Numbers'
import Dropdown from '../../../shared/ui/dropdown'
import ProfilePartnersSurvey from './survey'
import ProfilePartnersTerms from './terms'
import ProfilePartnersCapacities from './capacities'
import { axiosInstance } from '../../../shared/api'
import VerificationStatus from '../../../entities/partners/verification-status'
import { useAlertMessage } from 'shared/hooks'
import { FieldValues, useForm } from 'react-hook-form'
import { FormWrapper } from 'shared/ui/form/form-wrapper'
import moment from 'moment-timezone'
import { usePartnersActions } from 'features/partners/api/usePartnersActions'
import { Link } from 'react-router-dom'
import { PartnerPermissionModal } from 'entities/partners/permission-modal'
import { useGetTariff } from 'features/tariff/api/useGetTariff'
import { tokenParse } from 'shared/utils'
import AuthService from 'shared/services/auth/auth.service'
import { IPartnerResponse } from 'shared/services/types/partner.types'

const partnerListAccess = [
	`Партнёр`,
	`Корпоративный партнёр`,
	`Прайс-лист с меткой "Под заказ"`,
	`Размещение прайс-листа`,
	'Стать партнёром на год'
]

const ProfilePartners = () => {
	const methods = useForm({
		defaultValues: {
			settlements: [{ number: '', currency: '' }],

			delivery_services: []
		}
	})

	const {
		watch,
		control,
		formState: { errors }
	} = methods

	const { addLogo, addFilePartner, getPartnerInfo } = usePartnersActions()
	const { tariffName, isPending } = useGetTariff()
	const { is_company_owner } = tokenParse()

	const { addAlertMessage } = useAlertMessage()
	const [showOverlay, setShowOverlay] = useState<string | boolean>(false)
	const [partnerData, setPartnerData] = useState<IPartnerResponse>()
	const [surveyData, setSurveyData] = useState()
	const [termsData, setTermsData] = useState()

	const [isOpen, setIsOpen] = useState(false)

	const onCloseModal = () => {
		setIsOpen(false)
	}

	const handleSurveyData = (data: any) => {
		setSurveyData(data)
	}

	const handleTermsData = (data: any) => {
		setTermsData(data)
	}

	const handlePartnerData = async (data: FieldValues) => {
		const value = data
		const distributors = value?.distributors?.map((item: any) => item.value)
		const settlements = value.settlements.map((item: any) => ({
			...item,
			currency: item.currency.label
		}))
		const vendors = value?.vendors?.map((item: any) => ({
			vendor: item.vendor,
			status: item.status
		}))

		const logo = value.logo
		const files = Object.entries(value.files || {})
			.filter(([key, value]) => value)
			.map(([key, value]) => {
				return { type: key, file: value }
			})
		const workdays = value?.workdays
			.split(',')
			.reduce((acc: any, item: string) => {
				const [day, time] = item.split('/')
				return [...acc, { day, time }]
			}, [])

		files.forEach(item =>
			//@ts-ignore
			addFilePartner.mutateAsync(item).catch(err => {
				addAlertMessage(err, 'error')
			})
		)

		const dataObject = {
			settlements: settlements,
			vendors: vendors,
			distributors: distributors,
			surcharge: value?.surcharge,
			email: value?.email,
			nds: value?.nds?.value,
			phone_number: value?.phone_number,
			time_zone: moment.tz.guess(),
			site_url: value?.site_url,
			answer_time: value?.answer_time,
			product_delivery: value?.product_delivery,
			service_centers: value?.service_centers?.map((item: any) => item.label),
			product_documents: value.product_documents.value,
			workdays,
			spoken_language: value?.spoken_language?.value,
			description: value?.description,
			currency: value?.currency?.label,
			documents_info: value?.documents_info
		}

		if (logo) {
			addLogo.mutateAsync(logo).catch(err => {
				addAlertMessage(err, 'error')
			})
		}

		try {
			await axiosInstance.post('/partner/', dataObject)
			addAlertMessage('Заявка на становление партнером отправлена', 'info')
			AuthService.refresh()
			getPartnerInfo.refetch()
		} catch (error: any) {
			addAlertMessage(error, 'error')
			console.log(error)
		}
	}

	useEffect(() => {
		const { data, error, isFetching } = getPartnerInfo
		if (data) {
			setPartnerData(data)
		}
		// if (!isFetching && error) {
		// 	const errorMessage = error?.response?.data?.detail
		// 	setShowOverlay(errorMessage)
		// }
	}, [getPartnerInfo.data, getPartnerInfo.error])

	const checkSub = () => {
		return partnerListAccess.includes(tariffName)
	}
	const isCoorpTariff = checkSub()

	useEffect(() => {
		if (!isCoorpTariff && !isPending) {
			setIsOpen(true)
			setShowOverlay(true)
		}
		if (!is_company_owner && getPartnerInfo.data) {
			setShowOverlay('Вы не руководитель компании')
		}
	}, [isCoorpTariff, isPending, is_company_owner, getPartnerInfo.data])

	

	return (
		<>
			{getPartnerInfo.status && (
				<VerificationStatus status={partnerData?.status} />
			)}

			<div className='profile-container partners-container'>
				<div className='warning-text'>
					<p>
						Пожалуйста, вводите достоверные данные, которые, при необходимости,
						сможете подтвердить документально
					</p>
					<p>
						<strong style={{ color: 'red' }}>*</strong> - обязательное поле для
						заполнения
					</p>
				</div>
				<FormWrapper
					methods={methods}
					onSubmit={handlePartnerData}
					className='terms-form'
				>
					<Dropdown
						title='Анкета'
						content={
							<ProfilePartnersSurvey
								methods={methods}

								defaultData={partnerData}
							/>
						}
						number={<FirstNumber />}
					/>
					<Dropdown
						title='Расскажите об условиях'
						content={
							<ProfilePartnersTerms
								methods={methods}
								defaultData={partnerData}
							/>
						}
						number={<SecondNumber />}
					/>
					<Dropdown
						title='Ваши компетенции'
						content={
							<ProfilePartnersCapacities
								methods={methods}
								defaultData={partnerData}
							/>
						}
						number={<ThirdNumber />}
					/>

					{/* <Dropdown
						title='Документы'
						content={
							<ProfilePartnersDocuments
								onDataChange={handleCapacitiesData}
								methods={methods}
								defaultData={partnerData}
							/>
						}
						number={<FourNumber />}
					/> */}

					{!partnerData && (
						<button type='submit' className='btn partnerrequest-button'>
							Отправить запрос
						</button>
					)}
				</FormWrapper>

				{!!showOverlay && (!checkSub() || !is_company_owner) && (
					<div className='profile-overlay'>
						<div className='profile-overlay-content'>
							<p>
								{typeof showOverlay === 'string' ? (
									showOverlay
								) : (
									<>
										Для того чтобы стать партнером смените тариф на подходящий в
										разделе
										<Link to='/account/tariffs'> “Тарифы и оплата”</Link>
									</>
								)}
							</p>
						</div>
					</div>
				)}

				<PartnerPermissionModal
					isOpen={isOpen}
					onClose={onCloseModal}
					title='Партнёром можно стать на следующих тарифах:'
					data={partnerListAccess}
				/>
			</div>
		</>
	)
}

export default ProfilePartners

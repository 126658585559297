import React, { FC } from 'react'
import './Header.scss'
import { CabLogo, SettingsLogo, ListLogo } from '../../entities/header/logos'
import MainLogo from '../../assets/MainLogo'
import LanguagesFlags from '../../features/header/ui/language-flags'

interface IProps {
	openMenu?: () => void
	openProfile: () => void
	openSettings?: (onClose?: boolean) => void
	onCloseSettings: any
}

export const Header: FC<IProps> = ({
	openMenu,
	openProfile,
	openSettings,
	onCloseSettings
}) => {
	return (
		<header className='header'>
			<div className='container'>
				<nav className='header__nav'>
					<div className='logos'>
						<LanguagesFlags openSettings={onCloseSettings} />
						{/* <MenuLogo className="icon menu" turnMenu={openMenu} /> */}
						<SettingsLogo turnSettings={openSettings} />
					</div>
					<MainLogo />
					<div className='logos'>
						<ListLogo />
						<CabLogo turnProfile={openProfile} />
					</div>
				</nav>
			</div>
		</header>
	)
}

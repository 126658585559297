export const backend_url2 = 'https://kira.pw/api'
export const frontend_url = 'http://79.174.86.61'
export const SOCKET_LOCAL = `ws://79.174.86.61:8000/api`

export const ADMIN_ID = Number(process.env.REACT_APP_ADMIN_ID || 14)

export const backend_url =
	process.env.REACT_APP_API ||
	'http://79.174.86.61:8000/api' ||
	`http://79.174.86.61:8000/api`
export const chat_socket_url =
	process.env.REACT_APP_SOCKET ||
	'wss://kira.pw/api' ||
	'ws://79.174.86.61:8000/api'

export const getChatUrl = (string: string) => `/chat${string}`
export const getCompanyUrl = (string: string) => `/company${string}`
export const getHelpUrl = (string: string) => `/help${string}`
export const getPartnerUrl = (string: string) => `/partner${string}`
export const getPriceListUrl = (string: string) => `/prices${string}`
export const getProductUrl = (string: string) => `/product${string}`
export const getProjectUrl = (string: string) => `/project${string}`
export const getStatsUrl = (string: string) => `/stats/partner${string}`
export const getSubscriptionUrl = (string: string) => `/subscription${string}`
export const getSupplierUrl = (string: string) => `/supplier${string}`
export const getAuthUrl = (string: string) => `/user${string}`
export const getUserUrl = (string: string) => `/user${string}`
export const getUtilsUrl = (string: string) => `/utils${string}`

import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios'
import Cookies from 'js-cookie'

import { backend_url } from './config'
import { errorCatch } from './helpers'
import AuthService from 'shared/services/auth/auth.service'
import { removeTokensStorage } from 'shared/services/auth/auth.helpers'

export const axiosClassic = axios.create({
	baseURL: backend_url
})

export const axiosInstance = axios.create({
	// withCredentials: true,
	baseURL: backend_url
})

const interceptorsRequestSuccess = (
	config: InternalAxiosRequestConfig<any>
) => {
	const accessToken = Cookies.get('kira_token')

	if (config.headers && accessToken) {
		config.headers.Authorization = `Bearer ${accessToken}`
	}

	return config
}

axiosInstance.interceptors.request.use(interceptorsRequestSuccess)

const interceptorsResponseError = async (error: AxiosError<any>) => {
	const originalRequest = { ...error.config, _isRetry: false }

	if (
		([401].includes(error.response?.status || 0) ||
			// TODO error messages
			errorCatch(error) === 'jwt expired' ||
			errorCatch(error) === 'jwt must be provided') &&
		error.config &&
		!originalRequest._isRetry
	) {
		originalRequest._isRetry = true
		try {
			await AuthService.refresh()

			return axiosInstance.request(originalRequest)
		} catch (error) {
			if (errorCatch(error) === 'jwt expired') {
				removeTokensStorage()
			}
		}
	}

	throw error
}

axiosInstance.interceptors.response.use(async config => {
	return config
}, interceptorsResponseError)

export default axiosInstance

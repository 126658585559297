import { useQuery } from '@tanstack/react-query'
import UtilsService from 'shared/services/utils.service'

export const useGetLanguages = () => {
	return useQuery({
		queryFn: () => UtilsService.getLanguages(),
		queryKey: ['get languages'],
		select: ({ data }) => data,
		staleTime: Infinity
	})
}

export const useGetCurrencies = (isOptions?: boolean) => {
	return useQuery({
		queryFn: () => UtilsService.getCurrencies(),
		queryKey: ['get currencies'],
		staleTime: Infinity,
		select: ({ data }) => data
	})
}

export const useGetCountries = () => {
	return useQuery({
		queryFn: () => UtilsService.getCountries(),
		queryKey: ['get counties'],
		staleTime: Infinity,
		select: ({ data }) => data,
		retry: 0
	})
}

export const useGetExchange = () => {
	return useQuery({
		queryFn: () => UtilsService.getCurrenciesExchange(),
		queryKey: ['get exchange'],
		staleTime: Infinity,
		select: ({ data }) => data
	})
}

export const useGetDelivery = () => {
	return useQuery({
		queryFn: () => UtilsService.getDeliveryServices(),
		queryKey: ['get delivery'],
		staleTime: Infinity,
		select: ({ data }) => data
	})
}

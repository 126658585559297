import React, { FC, useEffect, useState } from 'react'
import { ReviewsSort } from '../../../partners/ui/reviews-sort'
import { ReviewBlock } from '../../../partners/ui/review-block'
import { ReviewStats } from 'entities/partners/reviews-stats'
import { useGetReviews } from 'features/reviews/api/useReviewsList'
import { IReviews, IReviewsFilter } from 'shared/services/types/supplier.types'
import { IPartnerResponse } from 'shared/services/types/partner.types'
import { DownloadMore } from 'shared/ui/form/download-more'

interface IProps {
	data?: IPartnerResponse
	supplier_type?:
		| 'Partner'
		| 'Distributor'
		| 'Manufacturer'
		| 'Store'
		| 'ChainStore'
}

const defaultPage = {
	page: 1,
	page_size: 20
}

const defaultRating = {
	5: 0,
	4: 0,
	3: 0,
	2: 0,
	1: 0
}
export const ReviewList: FC<IProps> = ({ data, supplier_type = 'Partner' }) => {
	const [reviews, setReviews] = useState<IReviews[]>([])
	const [filter, setFilter] = useState<IReviewsFilter>()
	const [rating, setRating] = useState(defaultRating)
	//@ts-ignore
	const { reviewsList } = useGetReviews(filter)

	useEffect(() => {
		if (data)
			setFilter({
				...defaultPage,
				supplier_id: data.id,
				supplier_type: supplier_type
			})
	}, [data])

	useEffect(() => {
		if (reviewsList.data?.main_info) {
			setRating(prev => ({ ...prev, ...reviewsList.data.main_info }))
		}
	}, [!!reviewsList.data?.main_info])

	useEffect(() => {
		if (reviewsList.data?.results) {
			setReviews(reviewsList.data.results)
		}
	}, [reviewsList.data])

	return (
		<div className='partner-reviews'>
			<div className='container'>
				<h2>Отзывы</h2>
				<div className='partner-reviews__row'>
					{!reviews.length || !data ? (
						<h3>Отзывы отсутствуют</h3>
					) : (
						<>
							<div className='partner-reviews__block'>
								<div className='partner-review-block'>
									{reviews.map(item => (
										<ReviewBlock
											key={item.id}
											data={item}
											supplierId={data.id}
										/>
									))}
								</div>
								{!!reviewsList.data?.next && (
									<DownloadMore onClick={reviewsList.fetchNextPage} />
								)}
							</div>
							<ReviewStats
								reviewData={{
									review: data.rating,
									reviews_count: data.reviews_count,
									rating: rating
								}}
							/>
						</>
					)}
				</div>
			</div>
		</div>
	)
}

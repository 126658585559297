import { useState, useEffect, FC } from 'react'
import './ProfileInfoCompany.scss'
import { MultiSelectCompanyTypes } from '../../../../../shared/ui/form/custom-select'
import { useCompanyActions } from 'features/company/api/useCompanyActions'
import { useInnCompany } from 'features/company/api/useCompanyInn'
import { useCompanyTypes } from 'features/company/api/useCompanyTypes'
import { useAlertMessage } from 'shared/hooks'
import { Input } from 'shared/ui/form/input'
import { useForm } from 'react-hook-form'
import { tokenParse } from 'shared/utils'
import AuthService from 'shared/services/auth/auth.service'
import { ICompanyType } from 'shared/services/types/company.types'
import { useGetUser } from 'features/profile/api/useGetUser'
import { Link } from 'react-router-dom'

const defaultCompanyData = {
	inn: '',
	name: '',
	type: '',
	full_name: '',
	english_name: '',
	director: '',
	ogrn: '',
	kpp: null,
	nds: undefined,
	index: '',
	juridical_address: '',
	actual_address: '',
	correspondence_address: '',
	bank_name: '',
	correspondent_bill: '',
	settlement_bill: '',
	constraints: undefined,
	license: undefined,

	city: '',
	address: '',
	bank_code: ''
}

interface IProps {
	companyInfo: any
}
const ProfileInfoCompany: FC<IProps> = ({ companyInfo }) => {
	const { is_company_owner, user_id } = tokenParse()
	const { userData } = useGetUser(user_id)
	const methods = useForm({ mode: 'all' })
	const {
		control,
		formState: { errors }
	} = methods

	const [companyData, setCompanyData] = useState(defaultCompanyData)
	const { addAlertMessage } = useAlertMessage()
	const { createCompany, updateCompany } = useCompanyActions()
	const { companyTypesData, error, isError, isPending } = useCompanyTypes()
	const { getInfoCompany } = useInnCompany(companyData?.inn)
	const [companyTypesList, setCompanyTypesList] = useState<ICompanyType[]>()
	const [innMessage, setInnMessage] = useState('')

	const [companyErrors, setCompanyErrors] = useState({
		inn: false,
		name: false,
		full_name: false,
		english_name: false,
		director: false,
		ogrn: false,
		kpp: false,
		index: false,
		nds: undefined,
		juridical_address: false,
		actual_address: false,
		correspondence_address: false,
		bank_name: false,
		correspondent_bill: false,
		settlement_bill: false,

		city: false,
		address: false,
		bank_code: false
	})

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target
		setCompanyData(prevState => ({
			...prevState,
			[name]: value
		}))
	}

	const handleErrorChange = (
		event: React.MouseEvent<HTMLInputElement, MouseEvent>
	) => {
		//@ts-ignore
		const { name } = event.target
		//@ts-ignore
		if (companyErrors[name]) {
			setCompanyErrors(prevState => ({
				...prevState,
				[name]: false
			}))
			setCompanyData(prevState => ({
				...prevState,
				[name]: ''
			}))
		}
	}

	const handleTypeSelect = (selectedOptions: any) => {
		setCompanyData(prevState => ({
			...prevState,
			type: selectedOptions.map((i: any) => {
				return i.id
			})
		}))
	}

	const getCompanyTypes = async () => {
		if (isError && error && !isPending) {
			//@ts-ignore
			return addAlertMessage(error, 'error')
		}
		setCompanyTypesList(companyTypesData)
	}

	useEffect(() => {
		const { isPending, data, isError } = getInfoCompany
		if (!isPending && isError) {
			setInnMessage('ошибка')
		} else if (!isPending && data) {
			setInnMessage(
				'Данные успешно получены, заполните оставшиеся поля и отправьте данные о своей компании'
			)
		}
	}, [getInfoCompany])

	useEffect(() => {
		getCompanyTypes()
	}, [companyTypesData])


	const sendCompanyInfo = async () => {
		methods.trigger()

		if (Object.keys(errors).length) return null

		if (companyInfo) {
			try {
				// @ts-ignore
				delete companyData.correspond_languages
				// @ts-ignore
				delete companyData.speak_languages

				const dataCompanyUpdate = {
					...companyData,
					country_id: userData?.country.id,
					english_name: methods.getValues('english_name')
				}
				// @ts-ignore
				await updateCompany.mutateAsync(dataCompanyUpdate)

				// window.location.reload()
			} catch (error: any) {
				addAlertMessage(error, 'error')
				if (error.response.status === 400) {
					const errorData = error.response.data
					setCompanyData(prevState => ({
						...prevState,
						...errorData
					}))
					setCompanyErrors(error.response.data)
				}
			}
		} else {
			try {
				if (!userData?.country.id) {
					return addAlertMessage('Заполните поле "Страна проживания" ', 'error')
				}
				//@ts-ignore
				await createCompany.mutateAsync({
					...companyData,
					//@ts-ignore
					country_id: userData?.country.id
				})
				AuthService.refresh()
			} catch (error: any) {
				if (error.response.status === 400) {
					const errorData = error.response.data
					setCompanyData(prevState => ({
						...prevState,
						...errorData
					}))
					setCompanyErrors(error.response.data)
				}
			}
		}
	}

	useEffect(() => {
		if (!updateCompany.isPending && updateCompany.isSuccess) {
			addAlertMessage('Сохранено', 'success')
		}
	}, [updateCompany.isPending, updateCompany.isSuccess])

	useEffect(() => {
		if (!createCompany.isPending && createCompany.isSuccess) {
			addAlertMessage('Сохранено', 'success')
		}
	}, [createCompany.isPending, createCompany.isSuccess])

	useEffect(() => {
		if (companyInfo) {
			setCompanyData(companyInfo)
			methods.setValue('english_name', companyInfo.english_name)
		}
	}, [companyInfo])

	const disabledField = !is_company_owner && companyInfo

	return (
		<form
			className='profilecompany-form'
			onSubmit={e => {
				e.preventDefault()
				sendCompanyInfo()
			}}
		>
			{disabledField && (
				<p style={{ marginBottom: '2rem' }} className='profile-message'>
					Вы не владелец компании. Изменить данные нельзя.
				</p>
			)}
			<div className='select-block'>
				<MultiSelectCompanyTypes
					disabled={disabledField}
					onChange={handleTypeSelect}
					multiSelectOptions={companyTypesList || []}
					defaultRef={companyData.type}
				/>
			</div>
			<div>
				{innMessage &&
					(innMessage === 'ошибка' ? (
						<p className='profile-message' style={{ color: 'red' }}>
							Произошла ошибка при подгрузке данных от налоговой
						</p>
					) : (
						<p className='profile-message' style={{ color: 'green' }}>
							{innMessage}
						</p>
					))}
				<div className='inputs-row'>
					<div className='input-block'>
						<input
							style={{
								color: companyErrors.inn ? 'red' : '',
								fontSize: companyErrors.inn ? '14px' : ''
							}}
							onClick={handleErrorChange}
							name='inn'
							value={companyData.inn}
							onChange={handleInputChange}
							disabled={disabledField}
							required
							className={
								companyData.inn !== undefined && companyData.inn !== ''
									? 'active'
									: ''
							}
						/>
						<div className='input-placeholder'>
							Введите налоговый номер <span>*</span>
						</div>
					</div>
					<div className='input-submit-block'>
						<button
							className='btn'
							type='button'
							disabled={disabledField}
							onClick={() => getInfoCompany.refetch()}
						>
							Заполнить
						</button>
					</div>
				</div>
			</div>
			<div className='inputs-row'>
				<div className='input-block'>
					<input
						style={{
							color: companyErrors.name ? 'red' : '',
							fontSize: companyErrors.name ? '14px' : ''
						}}
						onClick={handleErrorChange}
						name='name'
						disabled={disabledField}
						value={companyData.name}
						type='text'
						onChange={handleInputChange}
						required
						className={
							companyData.name !== undefined && companyData.name !== ''
								? 'active'
								: ''
						}
					/>
					<div className='input-placeholder'>
						Наименование<span>*</span>
					</div>
				</div>
				<Input
					placeholder='Наименование на английском'
					control={control}
					label='Наименование на английском'
					name='english_name'
					disabled={disabledField}
					error={errors}
					rules={{
						pattern: {
							message: 'Название должно содержать латинские буквы',
							// eslint-disable-next-line no-useless-escape
							value: /^[a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/
						}
					}}
				/>
			</div>
			<div className='inputs-row'>
				<div className='input-block'>
					<input
						style={{
							color: companyErrors.city ? 'red' : '',
							fontSize: companyErrors.city ? '14px' : ''
						}}
						name='city'
						type='text'
						onChange={handleInputChange}
						disabled={disabledField}
						value={companyData.city}
						className={
							companyData.city !== undefined && companyData.city !== ''
								? 'active'
								: ''
						}
					/>
					<div className='input-placeholder'>Город</div>
				</div>
				<div className='input-block'>
					<input
						style={{
							color: companyErrors.english_name ? 'red' : '',
							fontSize: companyErrors.english_name ? '14px' : ''
						}}
						name='address'
						disabled={disabledField}
						type='text'
						onChange={handleInputChange}
						value={companyData.address}
						className={companyData.address !== '' ? 'active' : ''}
					/>
					<div className='input-placeholder'>Адрес</div>
				</div>
			</div>
			<div className='inputs-row'>
				<div className='input-block'>
					<input
						style={{
							color: companyErrors.bank_code ? 'red' : '',
							fontSize: companyErrors.bank_code ? '14px' : ''
						}}
						name='bank_code'
						type='text'
						onChange={handleInputChange}
						disabled={disabledField}
						value={companyData.bank_code}
						className={
							companyData.bank_code !== undefined &&
							companyData.bank_code !== ''
								? 'active'
								: ''
						}
					/>
					<div className='input-placeholder'>БИК / SWIFT</div>
				</div>
				<div className='input-block'>
					<input
						style={{
							color: companyErrors.bank_name ? 'red' : '',
							fontSize: companyErrors.bank_name ? '14px' : ''
						}}
						onClick={handleErrorChange}
						type='text'
						name='bank_name'
						value={companyData.bank_name}
						onChange={handleInputChange}
						disabled={disabledField}
						className={
							companyData.bank_name !== undefined &&
							companyData.bank_name !== ''
								? 'active'
								: ''
						}
					/>
					<div className='input-placeholder'>Наименование банка</div>
				</div>
			</div>

			<div className='input-block'>
				<input
					style={{
						color: companyErrors.settlement_bill ? 'red' : '',
						fontSize: companyErrors.settlement_bill ? '14px' : ''
					}}
					onClick={handleErrorChange}
					type='text'
					name='settlement_bill'
					disabled={disabledField}
					value={companyData.settlement_bill}
					onChange={handleInputChange}
					className={
						companyData.settlement_bill !== undefined &&
						companyData.settlement_bill !== ''
							? 'active'
							: ''
					}
				/>
				<div className='input-placeholder'>Расчетный счет</div>
			</div>

			<div className='block-save block-term'>
				<div className='block-save-left'>
					<button className='btn' type='submit' disabled={disabledField}>
						Сохранить
					</button>
					<div className='terms-div'>
						<input
							checked={companyInfo}
							className='terms-button'
							type='checkbox'
							required
						/>
						<p>
							Нажав кнопку сохранить вы даете согласие на обработку{' '}
							<Link to='/privacy_policy'>персональных данных</Link> и
							подтверждаете, что вы совершеннолетний
						</p>
					</div>
				</div>
			</div>
		</form>
	)
}

export default ProfileInfoCompany

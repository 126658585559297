import { getSupplerIcon } from 'assets/SupplersIcons'
import clsx from 'clsx'
import { ProductToProjectPopup } from 'features/profile/ui/popup-profile'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { useValueCurrency } from 'shared/hooks'
import { IDataCountry } from 'shared/services/types/utils.types'
import CollapsibleText from 'shared/ui/collapsible'
import { PromptText } from 'shared/ui/prompt-text'
import { reFormatNum } from 'shared/utils'
import { distributerList } from '..'
import { countryCodeName } from 'shared/constants'
interface IProps {
	settingsParam?: string[]
	productsList: any[]

	onSortTableProducts: (name: string) => void
	currentCurrency: any
	tariff: string
	authState: boolean
	countries: IDataCountry[]
	data: any
}

const tariffsBlur = [undefined, 5, 7]

export const TableProducts: FC<IProps> = ({
	settingsParam,
	productsList,
	onSortTableProducts,
	currentCurrency,
	authState,
	tariff,
	countries,
	data
}) => {
	const { getCurrencyCounty, currency } = useValueCurrency(false, 0)

	const currentPriceValue = (prices: any[]) => {
		return currentCurrency
			? getCurrencyCounty(prices[2].price) || '-'
			: Number(prices[2].price)
	}

	return (
		<>
			<div className='product-table'>
				<table>
					<thead>
						<tr>
							{settingsParam?.includes('country_id') && (
								<th className='country-col'>Страна</th>
							)}

							{settingsParam?.includes('article') && (
								<th className='article-col'>Артикул</th>
							)}
							{settingsParam?.includes('name') && (
								<th className={clsx('article-col', 'article-col-name')}>
									Название
								</th>
							)}
							{settingsParam?.includes('prices') && (
								<th
									className='currency-col cursor'
									onClick={() => onSortTableProducts('price')}
								>
									{productsList[0]?.prices[0]?.currency_code}
								</th>
							)}

							{settingsParam?.includes('prices') &&
								currentCurrency?.label !== 'USD' && (
									<th
										className='currency-col cursor'
										onClick={() => onSortTableProducts('price')}
									>
										{currentCurrency?.label ||
											productsList[0]?.prices[2]?.currency_code}
									</th>
								)}
							{settingsParam?.includes('stock_available') && (
								<th
									className='available-col cursor'
									onClick={() => onSortTableProducts('stock_available')}
								>
									Доступно
								</th>
							)}
							{settingsParam?.includes('stock_quantity') && (
								<th
									className='transit-col cursor'
									onClick={() => onSortTableProducts('stock_quantity')}
								>
									На складе
								</th>
							)}

							{settingsParam?.includes('transit_quantity') && (
								<th className='transit-col'> В транзите</th>
							)}
							{settingsParam?.includes('transit_available') && (
								<th
									className='transit-col cursor'
									onClick={() => onSortTableProducts('transit_available')}
								>
									Доступно в транзите
								</th>
							)}
							{settingsParam?.includes('supplier') && (
								<th
									className={
										authState && tariff !== 'Бесплатный'
											? 'manufacturer-col cursor'
											: 'manufacturer-col cursor not-authorized'
									}
								>
									Поставщик
								</th>
							)}
							{authState && <th className='tolist-col'>В список</th>}
						</tr>
					</thead>
					<tbody>
						{productsList.map(i => {
							return (
								<tr key={i.id}>
									{settingsParam?.includes('country_id') && (
										<td className='country-col' aria-label='Страна'>
											{/* @ts-ignore */}
											<p>{countryCodeName[i.country_id]}</p>
											<img
												src={countries?.find(l => l.id == i.country_id)?.flag}
												height='24'
												width='34'
											/>
										</td>
									)}
									{settingsParam?.includes('article') && (
										<td className='article-col' aria-label='Артикул'>
											<p>{i.article}</p>
										</td>
									)}
									{settingsParam?.includes('name') && (
										<td className='name-col' aria-label='Название'>
											<CollapsibleText text={i.name} />
										</td>
									)}

									{settingsParam?.includes('prices') && (
										<td
											className='currency-col'
											aria-label={productsList[0].prices[0].currency_code}
										>
											{getCurrencyCounty(i.prices[0].price)}
										</td>
									)}

									{settingsParam?.includes('prices') &&
										currentCurrency?.label !== 'USD' && (
											<td className='currency-col'>
												<p>
													{currentCurrency
														? currentPriceValue(i.prices)
														: reFormatNum(Number(i.prices[2].price).toFixed(0))}
												</p>
											</td>
										)}
									{settingsParam?.includes('stock_available') && (
										<td
											className='available-col'
											aria-label='Доступно'
											onClick={() => onSortTableProducts('stock_available')}
										>
											<p>{i.stock_available}</p>
										</td>
									)}
									{settingsParam?.includes('stock_quantity') && (
										<td
											className='instock-col'
											aria-label='На складе'
											onClick={() => onSortTableProducts('stock_quantity')}
										>
											<p>{i.stock_quantity}</p>
										</td>
									)}

									{settingsParam?.includes('transit_available') && (
										<td
											className='transit-col'
											aria-label='В транзите'
											onClick={() => onSortTableProducts('transit_available')}
										>
											<p>{i.transit_available}</p>
										</td>
									)}

									{settingsParam?.includes('transit_quantity') && (
										<td
											className='transit-col'
											aria-label='Доступно в транзите'
										>
											<p>{i.transit_quantity ? i.transit_quantity : '*'}</p>
										</td>
									)}
									{settingsParam?.includes('supplier') && (
										<td
											className={
												authState && tariff !== 'Бесплатный'
													? 'manufacturer-col'
													: 'manufacturer-col not-authorized'
											}
											aria-label='Поставщик'
										>
											{authState ? (
												<div className='manufacturer-col-wrap'>
													<div
														style={{
															position: 'relative',
															width: '15px',
															height: '15px'
														}}
													>
														<PromptText
															text={`${
																distributerList.find(
																	item =>
																		item.value ===
																		i?.supplier?.type?.toLowerCase()
																)?.help
															}`}
														>
															{getSupplerIcon(i?.supplier?.type?.toLowerCase())}
														</PromptText>
													</div>
													<Link
														className='distributor-col'
														to={`/${i?.supplier?.type}-page/${i?.supplier?.slug}`}
													>
														{i?.supplier?.name}
													</Link>
												</div>
											) : (
												<div className='blur '>
													{getSupplerIcon(i?.supplier?.type?.toLowerCase())}
													{i?.supplier?.name}

													<Link className='btn-register' to={'/register/'}>
														Зарегистрируйтесь <br />
														для просмотра поставщиков
													</Link>
												</div>
											)}
										</td>
									)}
									{authState && (
										<ProductToProjectPopup
											dataProduct={i}
											isBlur={tariffsBlur.includes(data?.tariff?.id)}
										/>
									)}
								</tr>
							)
						})}
					</tbody>
				</table>
			</div>

			<div className='products-mob'>
				{productsList.map(i => {
					return (
						<div className='products-mob-item' key={i.id}>
							{
								<div className='products-mob-item__row'>
									<div className='products-mob-item__col-name'>
										{settingsParam?.includes('country_id') && 'Страна'}
										<br />
										Артикул
									</div>
									<div className='products-mob-item__col-value'>
										{settingsParam?.includes('country_id') && (
											<>
												<img
													className='flag'
													src={countries?.find(l => l.id == i.country_id)?.flag}
													height='24'
													width='34'
												/>

												<p className='code'>
													{/* @ts-ignore */}
													{countryCodeName[i.country_id]}
												</p>
											</>
										)}

										<p className='article'>{i.article}</p>
									</div>
								</div>
							}
							<div className='products-mob-item__row'>
								<div className='products-mob-item__col-name'>Название</div>
								<div className='products-mob-item__col-value'>
									<p>{i.name}</p>
								</div>
							</div>
							{settingsParam?.includes('stock_available') ? (
								<div className='products-mob-item__row'>
									<div className='products-mob-item__col-name'>В наличие</div>
									<div
										className='products-mob-item__col-value'
										aria-label='Доступно'
									>
										<p>{i.stock_available}</p>
									</div>
								</div>
							) : (
								<></>
							)}
							{settingsParam?.includes('transit_available') ? (
								<div className='products-mob-item__row'>
									<div className='products-mob-item__col-name'>
										Доступно в транзите
									</div>
									<div
										className='products-mob-item__col-value'
										aria-label='В транзите'
									>
										<p>{i.transit_available}</p>
									</div>
								</div>
							) : (
								<></>
							)}
							{settingsParam?.includes('transit_quantity') ? (
								<div className='products-mob-item__row'>
									<div className='products-mob-item__col-name'>В транзите</div>
									<div
										className='products-mob-item__col-value'
										aria-label='Доступно в транзите'
									>
										<p>{i.transit_quantity}</p>
									</div>
								</div>
							) : (
								<></>
							)}
							{settingsParam?.includes('supplier') && i.supplier ? (
								<div className='products-mob-item__row'>
									<div className='products-mob-item__col-name'>Поставщик</div>
									<div
										className='products-mob-item__col-value'
										aria-label='Поставщик'
									>
										{authState && tariff !== 'Бесплатный' ? (
											<div className='distributor'>
												<div className='distributor-relative'>
													<PromptText
														text={`${
															distributerList.find(
																item =>
																	item.value ===
																	i?.supplier?.type?.toLowerCase()
															)?.help
														}`}
													>
														{getSupplerIcon(i?.supplier?.type?.toLowerCase())}
													</PromptText>
												</div>

												<Link
													className='distributor-col'
													to={`/${i?.supplier.type}-page/${i?.supplier?.slug}`}
												>
													{i.supplier.name}
												</Link>
											</div>
										) : (
											<div className='distributor blur-block'>
												{getSupplerIcon(i?.supplier?.type?.toLowerCase())}
												<span className='distributor-col'>
													{i.supplier.name}
												</span>
											</div>
										)}
									</div>
								</div>
							) : (
								<></>
							)}
							{settingsParam?.includes('prices') ? (
								<div className='products-mob-item__row'>
									<div className='products-mob-item__col-name products-mob-item__col-name-center'>
										Цена
									</div>
									<div className='products-mob-item__col-value'>
										<div className='price'>
											<p>
												{reFormatNum(currentPriceValue(i.prices))}{' '}
												{!isNaN(Number(i.prices[2].price)) ? currency : ''}
											</p>
										</div>
										{authState && !tariffsBlur.includes(data?.tariff?.id) ? (
											// @ts-ignore
											<ProductToProjectPopup dataProduct={i} />
										) : authState && tariffsBlur.includes(data?.tariff?.id) ? (
											<Link className='btn' to='/account/tariff/'>
												Приобретите <br />
												подписку
											</Link>
										) : (
											<Link className='btn' to='/register/'>
												Зарегистрируйтесь <br />
												для просмотра поставщиков
											</Link>
										)}
									</div>
								</div>
							) : (
								<></>
							)}
						</div>
					)
				})}
			</div>
		</>
	)
}

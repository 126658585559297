import { useQuery } from '@tanstack/react-query'
import CompanyService from 'shared/services/company.service'

export const useInnCompany = (inn: string) => {
	const getInfoCompany = useQuery({
		queryFn: () => CompanyService.getInfoByInn(inn),
		queryKey: ['get info company'],
		enabled: false,
		select: ({ data }) => data,
		retry: 0
	})

	const getReportCompany = useQuery({
		queryFn: () => CompanyService.reportCompany(inn),
		queryKey: ['get info company report'],
		select: ({ data }) => data,
		enabled: !!inn
	})

	return { getInfoCompany, getReportCompany }
}

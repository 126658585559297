const maskToPlaceholder = (mask: string) => {
	return mask.replace(/9/g, '_')
}

export const maskPhone = {
	Россия: {
		mask: '+ 7 (999) 999-99-99',
		placeholder: maskToPlaceholder(`+ 7 (999) 999-99-99`)
	},
	США: {
		mask: '(999) 999-9999',
		placeholder: maskToPlaceholder(`(999) 999-9999`)
	},
	Казахстан: {
		mask: '+ 7 (999) 999-99-99',
		placeholder: maskToPlaceholder(`+ 7 (999) 999-99-99`)
	},
	Европа: {
		mask: '+ 44 999 999-999-999',
		placeholder: maskToPlaceholder(`+ 44 999 999-999-999`)
	},
	Украина: {
		mask: '+ 38 (999) 999-99-99',
		placeholder: maskToPlaceholder(`+ 38 (999) 999-99-99`)
	},
	Беларусь: {
		mask: '+ 375 99 999 99 99',
		placeholder: maskToPlaceholder(`+ 375 99 999 99 99`)
	},
	Кыргызстан: {
		mask: '+996 99 999 99 99',
		placeholder: maskToPlaceholder(`+(996) 99 999 99 99`)
	},
	Узбекистан: {
		mask: '+ 998 99 999 99 99',
		placeholder: maskToPlaceholder(`+ 998 99 999 99 99`)
	}
}

import React, { FC } from 'react'
import { IPropsInput } from '../types'
import { Controller } from 'react-hook-form'
import ReactInputMask from 'react-input-mask'
import clsx from 'clsx'

export const Input: FC<IPropsInput> = ({
	error,
	required,
	disabled,
	control,
	name,
	className,
	label,
	rules,
	info,
	textArea,
	mask,
	type = 'text'
}) => {
	const errorMessage =
		typeof error === 'string' ? error : error?.[name]?.message

	return (
		<Controller
			name={name}
			control={control}
			rules={rules}
			render={({ field: { value, onChange, onBlur } }) => {
				return (
					<div
						className={clsx('input-block', className)}
						style={{ border: errorMessage ? '1px solid red' : '' }}
					>
						{mask ? (
							<ReactInputMask
								onBlur={onBlur}
								required={required}
								value={value}
								style={{ paddingTop: '15px' }}
								onChange={onChange}
								className='input-phone'
								mask={mask}
								disabled={disabled}
							/>
						) : textArea ? (
							<textarea
								onBlur={onBlur}
								style={{
									color: errorMessage ? 'red' : '',
									fontSize: errorMessage ? '14px' : ''
								}}
								disabled={disabled}
								name='name'
								value={value}
								onChange={onChange}
								required={required}
								className={clsx(value && 'active')}
							/>
						) : (
							<input
								style={{
									color: errorMessage ? 'red' : '',
									fontSize: errorMessage ? '14px' : ''
								}}
								name='name'
								onBlur={onBlur}
								disabled={disabled}
								value={value}
								type={type}
								onChange={onChange}
								required={required}
								className={clsx(value && 'active')}
							/>
						)}
						{}
						<div
							className={clsx('input-placeholder', mask && value && 'active')}
						>
							{label}
							{required && <span>*</span>}
						</div>

						{info && (
							<>
								<div className='input-note'>
									<svg
										width='18'
										height='18'
										viewBox='0 0 18 18'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M7.59375 6.65625C7.59375 5.8796 8.22335 5.25 9 5.25C9.77665 5.25 10.4062 5.8796 10.4062 6.65625C10.4062 7.17183 10.1288 7.62261 9.71502 7.86741C9.35853 8.07832 9 8.39829 9 8.8125V9.75'
											stroke='#777777'
											strokeLinecap='round'
										></path>
										<circle cx='9' cy='12' r='0.75' fill='#777777'></circle>
										<path
											d='M5.25 2.50337C6.35315 1.86523 7.63392 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 7.63392 1.86523 6.35315 2.50337 5.25'
											stroke='#777777'
											strokeLinecap='round'
										></path>
									</svg>
								</div>
								<div className='input-note-desc'>{info}</div>
							</>
						)}

						<div className='input-error'>
							{errorMessage && (
								<span>
									{/* @ts-ignore */}
									{errorMessage}
								</span>
							)}
						</div>
					</div>
				)
			}}
		/>
	)
}

import { RouterProvider } from 'react-router-dom'

import './styles/App.scss'
import './styles/index.scss'

import { router } from './providers/route'
import { MainProvider } from './providers'
import { AuthProvider } from 'services/AuthContext'
import { useEffect } from 'react'

export const App = () => {
	useEffect(() => {
		localStorage.setItem('kira_check', 'ok')
	}, [1000])
	return (
		<MainProvider>
			<AuthProvider>
				<RouterProvider router={router} />
			</AuthProvider>
		</MainProvider>
	)
}

import Cookies from 'js-cookie'

export interface ITokens {
	access: string
	refresh: string
}

export const saveTokensStorage = (data: ITokens) => {
	Cookies.set('kira_token', data.access)
	localStorage.setItem('kira_login', 'true')
	localStorage.setItem('refresh', data.refresh)

	window.dispatchEvent(new Event('storage'))
	window.dispatchEvent(new Event('cookiechange'))
}

export const removeTokensStorage = () => {
	Cookies.remove('kira_token')
	Cookies.remove('kira-country')
	Cookies.remove('kira_currency')
	localStorage.removeItem('refresh')

	window.dispatchEvent(new Event('storage'))
	window.dispatchEvent(new Event('cookiechange'))
}

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAlertMessage } from 'shared/hooks'
import UserService from 'shared/services/user.service'

export const useUpdateUser = (id: number) => {
	const queryClient = useQueryClient()

	const { addAlertMessage } = useAlertMessage()
	const { isPending, isError, mutate, mutateAsync, isSuccess, data, error } =
		useMutation({
			mutationFn: (data: any) => UserService.updatePartUser(id, data),
			mutationKey: ['update user', id],
			onSuccess: () => {
				queryClient.invalidateQueries({
					queryKey: [`get user`]
				})
			}
		})

	const updateUser = async (data: any, callback?: () => void) => {
		mutateAsync(data)
			.then(res => {
				callback?.()
				return res
			})
			.catch(error => {
				addAlertMessage(error)
				return error
			})
	}
	return {
		isPending,
		isError,
		error,
		mutate,
		updateUser,
		isSuccess,
		data,
		updateUserAsync: mutateAsync
	}
}

import {
	IChainStoreList,
	IStoreDetail,
	ISupplierFilter,
	TManufacturerDetail,
	IReviewsFilter,
	IReviewsData,
	IReviewsList,
	IReviewComplaint,
	IReviewUpdate,
	IReviewCheck,
	IReviewsScore
} from './types/supplier.types'

import { axiosInstance } from 'shared/api'
import { getSupplierUrl } from 'shared/api/config'

const SupplierService = {
	async getChainStore(data?: ISupplierFilter) {
		const response = await axiosInstance.get<IChainStoreList[]>(
			getSupplierUrl(`/chain_stores/`),
			{ params: data }
		)
		return response
	},
	async getChainStoreById(id: number) {
		const response = await axiosInstance.get<IStoreDetail>(
			getSupplierUrl(`/chain_stores/${id}/`)
		)
		return response
	},
	async getDistributors(data?: ISupplierFilter) {
		const response = await axiosInstance.get<IChainStoreList>(
			getSupplierUrl(`/distributors/`),
			{ params: data }
		)
		return response
	},
	async getDistributorById(id: string) {
		const response = await axiosInstance.get<IStoreDetail>(
			getSupplierUrl(`/distributors/${id}/`)
		)
		return response
	},

	async getManufacturers(data?: ISupplierFilter) {
		const countries = data?.country?.join(',')

		const response = await axiosInstance.get<IChainStoreList>(
			getSupplierUrl(`/manufacturers/`),
			{ params: { countries } }
		)
		return response
	},

	async getManufacturerById(id: number) {
		const response = await axiosInstance.get<TManufacturerDetail>(
			getSupplierUrl(`/manufacturers/${id}/`)
		)
		return response
	},

	async getReviews(data?: IReviewsFilter) {
		const response = await axiosInstance.get<IReviewsList>(
			getSupplierUrl(`/review/`),
			{ params: data }
		)
		return response
	},

	async getCheckReview(data: IReviewCheck) {
		const response = await axiosInstance.get<IReviewsList>(
			getSupplierUrl(`/review/check/`),
			{ params: data }
		)
		return response
	},
	async addReviews(data: IReviewsData) {
		const response = await axiosInstance.post<IReviewsData>(
			getSupplierUrl(`/review/`),
			data
		)
		return response
	},

	async addReviewsComplaint(data: IReviewComplaint) {
		const response = await axiosInstance.post<IReviewComplaint>(
			getSupplierUrl(`/review/complaint/`),
			data
		)
		return response
	},
	async changeReview(id: number, data: IReviewUpdate) {
		const response = await axiosInstance.patch<IReviewUpdate>(
			getSupplierUrl(`/review/${id}/`),
			data
		)
		return response
	},

	async deleteReview(id: number) {
		const response = await axiosInstance.delete<IReviewUpdate>(
			getSupplierUrl(`/review/${id}/`)
		)
		return response
	},

	async addReviewAnswer(id: number, data: IReviewsData) {
		const response = await axiosInstance.patch<IReviewUpdate>(
			getSupplierUrl(`/review/${id}/rep_response/`),
			data
		)
		return response
	},

	async changeScoreReview(id: number, data: IReviewsScore) {
		const response = await axiosInstance.put<IReviewUpdate>(
			getSupplierUrl(`/review/${id}/score/`),
			data
		)
		return response
	},
	async getStoreList(data?: ISupplierFilter) {
		const response = await axiosInstance.get<IReviewUpdate>(
			getSupplierUrl(`/stores/`),
			{ params: data }
		)
		return response
	},
	async getStoreById(id: number) {
		const response = await axiosInstance.get<IReviewUpdate>(
			getSupplierUrl(`/stores/${id}/`)
		)
		return response
	}
}

export default SupplierService

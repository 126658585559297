export const toFormData = (data: any, fieldsDelete?: string[]) => {
  const form = new FormData();

  for (const key in data) {
    const value = data[key] !== undefined ? data[key] : '';
    form.append(key, value);
  }

  fieldsDelete?.forEach((field) => {
    form.delete(field);
  });

  return form;
};

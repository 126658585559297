import React, { useEffect, useState } from 'react'
import Dropdown from 'shared/ui/dropdown'
import 'assets/input.scss'
import 'assets/Numbers'
import './SourcesStatistics.scss'
import { FirstNumber, SecondNumber } from 'assets/Numbers'

import DataSource from './DataSource'
import DownloadMethod from './DownloadMethod'
import StatisticsDiagram from './StatisticsDiagram'
import BannerSupport from 'entities/banner-support'
import { usePriceSource } from 'features/profile/api/usePriceSource'
import { Link } from 'react-router-dom'
import { PartnerPermissionModal } from 'entities/partners/permission-modal'
import { useGetTariff } from 'features/tariff/api/useGetTariff'

const partnerListAccess = [
	'Партнёр',
	'Корпоративный партнёр',
	`Прайс-лист с меткой "Под заказ"`,
	`Размещение прайс-листа`
]

const SourcesStatistics = () => {
	const { getSource } = usePriceSource()
	const [showOverlay, setShowOverlay] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const { tariffName, isPending } = useGetTariff()

	const onCloseModal = () => {
		setIsOpen(false)
	}
	useEffect(() => {
		if (isPending) return
		if (getSource.error) {
			setShowOverlay(getSource.error?.response?.data?.detail)
		}
		if (!partnerListAccess.includes(tariffName)) {
			setIsOpen(true)
			setShowOverlay(true)
		}
	}, [getSource.error, isPending])

	const [isModalVisible, setModalVisible] = useState(false)

	const openModal = () => {
		setModalVisible(true)
	}

	const closeModal = () => {
		setModalVisible(false)
	}

	return (
		<>
			<div className='sources-statistics'>
				<div className='profile-container'>
					<div className='warning-text'>
						<p>Чтобы ваше предложение стало доступно, следуйте инструкции.</p>
					</div>
					<Dropdown
						title='Источник данных'
						content={<DataSource />}
						number={<FirstNumber />}
					/>
					<Dropdown
						title='Способ загрузки'
						content={<DownloadMethod />}
						number={<SecondNumber />}
					/>
				</div>

				<StatisticsDiagram sourceList={getSource?.data} />
			</div>
			<div className='sources-statistics-support'>
				<BannerSupport />
			</div>

			{!!showOverlay && (
				<div className='profile-overlay'>
					<div className='profile-overlay-content'>
						<p>
							{typeof showOverlay === 'string' ? (
								<>{showOverlay}</>
							) : (
								<>
									Для работы с источниками смените тариф на подходящий в разделе
									<Link to='/account/tariffs'> “Тарифы и оплата”</Link>
								</>
							)}
						</p>
					</div>
				</div>
			)}
			<PartnerPermissionModal
				isOpen={isOpen}
				onClose={onCloseModal}
				title='Работать с источниками можно на:'
				data={partnerListAccess}
			/>
		</>
	)
}

export default SourcesStatistics
